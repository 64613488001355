import { createApp } from "vue";
import router from "./router";
import App from "./App.vue";
import { Lazyload } from "vant";

import "@/assets/styles/index.less";

import "vant/es/notify/style";
import "vant/es/toast/style";
import "vant/es/dialog/style";

createApp(App).use(router).use(Lazyload).mount("#app");
