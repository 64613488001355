import { createRouter, createWebHashHistory } from "vue-router";

const routes = [
  {
    path: "/",
    redirect: "/map",
  },
  {
    path: "/map",
    meta: { title: "GIS地图" },
    component: () => import("@/views/MapView.vue"),
  },
  {
    path: "/sphere/:pointId",
    meta: { title: "全景图浏览" },
    component: () => import("@/views/SphereView.vue"),
    props: true,
  },
];

const router = createRouter({
  history: createWebHashHistory(),
  routes,
});

router.beforeEach((to, from, next) => {
  if (to.meta.title) {
    document.title = to.meta.title;
  }
  next();
});

export default router;
